import React, { Component } from 'react';
import Chart from "chart.js/auto";
import { Bar } from "react-chartjs-2";
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';



export class FetchData extends Component {
    static displayName = FetchData.name;
    chartReference = {};

    constructor(props) {
        super(props);
        this.state = {
            forecasts: [], loading: true,
            devices: ["1", "5", "6"],
            labels: ["January", "February", "March", "April", "May", "June"],
            dataSet: {
                labels: ["January", "February", "March", "April", "May", "June"],
                datasets: [
                    {
                        label: "Today",
                        backgroundColor: "rgb(255, 99, 132)",
                        borderColor: "rgb(255, 99, 132)",
                        data: [0, 10, 5, 2, 20, 30, 45],
                    },
                    {
                        label: "Day 1",
                        backgroundColor: "rgb(0, 156, 0)",
                        borderColor: "rgb((0, 156, 0)",
                        data: [0, 10, 5, 2, 20, 30, 45],
                    },
                    {
                        label: "Day 2",
                        backgroundColor: "rgb(11, 99, 255)",
                        borderColor: "rgb(11, 99, 255)",
                        data: [0, 10, 5, 2, 20, 30, 45],
                    }
                ],
            },
            dataSet2: {
                labels: ["January", "February", "March", "April", "May", "June"],
                datasets: [
                    {
                        label: "Today",
                        backgroundColor: "rgb(255, 99, 132)",
                        borderColor: "rgb(255, 99, 132)",
                        data: [0, 10, 5, 2, 20, 30, 45],
                    },
                    {
                        label: "Day 1",
                        backgroundColor: "rgb(0, 156, 0)",
                        borderColor: "rgb((0, 156, 0)",
                        data: [0, 10, 5, 2, 20, 30, 45],
                    },
                    {
                        label: "Day 2",
                        backgroundColor: "rgb(11, 99, 255)",
                        borderColor: "rgb(11, 99, 255)",
                        data: [0, 10, 5, 2, 20, 30, 45],
                    }
                ],
            },
            dataFlyIn: {
                labels: ["January", "February", "March", "April", "May", "June"],
                datasets: [
                    {
                        label: "Today",
                        backgroundColor: "rgb(255, 99, 132)",
                        borderColor: "rgb(255, 99, 132)",
                        data: [0, 10, 5, 2, 20, 30, 45],
                    },
                    {
                        label: "Day 1",
                        backgroundColor: "rgb(0, 156, 0)",
                        borderColor: "rgb((0, 156, 0)",
                        data: [0, 10, 5, 2, 20, 30, 45],
                    },
                    {
                        label: "Day 2",
                        backgroundColor: "rgb(11, 99, 255)",
                        borderColor: "rgb(11, 99, 255)",
                        data: [0, 10, 5, 2, 20, 30, 45],
                    }
                ],
            },
            dataFlyOut: {
                labels: ["January", "February", "March", "April", "May", "June"],
                datasets: [
                    {
                        label: "Today",
                        backgroundColor: "rgb(255, 99, 132)",
                        borderColor: "rgb(255, 99, 132)",
                        data: [0, 10, 5, 2, 20, 30, 45],
                    },
                    {
                        label: "Day 1",
                        backgroundColor: "rgb(0, 156, 0)",
                        borderColor: "rgb((0, 156, 0)",
                        data: [0, 10, 5, 2, 20, 30, 45],
                    },
                    {
                        label: "Day 2",
                        backgroundColor: "rgb(11, 99, 255)",
                        borderColor: "rgb(11, 99, 255)",
                        data: [0, 10, 5, 2, 20, 30, 45],
                    }
                ],
            },
            dataPrediction: {
                labels: ["January", "February", "March", "April", "May", "June"],
                datasets: [
                    {
                        label: "Prediction",
                        backgroundColor: "rgb(255, 99, 132)",
                        borderColor: "rgb(255, 99, 132)",
                        data: [0, 10, 5, 2, 20, 30, 45],
                    }
                    ,
                    {
                        label: "Prediction2",
                        backgroundColor: "rgb(0, 156, 0)",
                        borderColor: "rgb((0, 156, 0)",
                        data: [0, 10, 5, 2, 20, 30, 45],
                    }

                ],
            }
        };
        this.populateWeatherData = this.populateWeatherData.bind(this);
    }

    componentDidMount() {
        this.populateWeatherData({'value':1});
    }



    renderForecastsTable(forecasts, chartData, chartDataHourly, chartFlyIn, chartFlyOut, chartPrediction, devices, object) {

        const { populateWeatherData } = object;
        return (
            <div>
                <Dropdown
                    defaultValue="1"
                    options={devices}
                    onChange={value => populateWeatherData(value)}
                />
                <Bar redraw={true} data={chartData} options={{
                    plugins: {
                        title: {
                            display: true,
                            text: "General"
                        }
                    }
                }} />
                <Bar redraw={true} data={chartFlyIn} options={{
                    plugins: {
                        title: {
                            display: true,
                            text: "Fly In"
                        }
                    }
                }} />
                <Bar redraw={true} data={chartFlyOut} options={{
                    plugins: {
                        title: {
                            display: true,
                            text: "Fly Out"
                        }
                    }
                }} />
                <Bar redraw={true} data={chartDataHourly} options={{
                    plugins: {
                        title: {
                            display: true,
                            text: "Total Hourly"
                        }
                    }
                }} />
                <Bar redraw={true} data={chartPrediction} options={{
                    plugins: {
                        title: {
                            display: true,
                            text: "Bird Prediction"
                        }
                    }
                }} />

                {/*<table className="table table-striped" aria-labelledby="tableLabel">*/}
                {/*  <thead>*/}
                {/*    <tr>*/}
                {/*      <th>Date</th>*/}
                {/*      <th>Device ID</th>*/}
                {/*      <th>Total</th>*/}
                {/*      <th>Count</th>*/}
                {/*    </tr>*/}
                {/*  </thead>*/}
                {/*  <tbody>*/}
                {/*    {forecasts.records.map(forecast =>*/}
                {/*      <tr key={forecast.date}>*/}
                {/*        <td>{forecast.date}</td>*/}
                {/*        <td>{forecast.customerId}</td>*/}
                {/*            <td>{forecasts.todayCount}</td>*/}
                {/*            <td>{forecast.location}</td>*/}
                {/*      </tr>*/}
                {/*    )}*/}
                {/*  </tbody>*/}
                {/*        </table>*/}

            </div>
        );
    }

    render() {
        let data = this.state.dataSet;
        data.labels = this.state.forecasts.labels;
        data.datasets[0].data = this.state.forecasts.data;
        data.datasets[1].data = this.state.forecasts.data1;
        data.datasets[2].data = this.state.forecasts.data2;

        let data2 = this.state.dataSet2;
        data2.labels = this.state.forecasts.labelsHourly;
        data2.datasets[0].data = this.state.forecasts.dataHourly;
        data2.datasets[1].data = this.state.forecasts.data1Hourly;
        data2.datasets[2].data = this.state.forecasts.data2Hourly;

        let flyIn = this.state.dataFlyIn;
        flyIn.labels = this.state.forecasts.labels;
        flyIn.datasets[0].data = this.state.forecasts.flyIn;
        flyIn.datasets[1].data = this.state.forecasts.flyIn2;
        flyIn.datasets[2].data = this.state.forecasts.flyIn3;


        let flyOut = this.state.dataFlyOut;
        flyOut.labels = this.state.forecasts.labels;
        flyOut.datasets[0].data = this.state.forecasts.flyOut;
        flyOut.datasets[1].data = this.state.forecasts.flyOut2;
        flyOut.datasets[2].data = this.state.forecasts.flyOut3;

        let prediction = this.state.dataPrediction;
        prediction.labels = this.state.forecasts.labelPrediction;
        prediction.datasets[0].data = this.state.forecasts.prediction;
        prediction.datasets[1].data = this.state.forecasts.prediction2;




        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : this.renderForecastsTable(this.state.forecasts, data, data2, flyIn, flyOut, prediction, this.state.devices, this);


        return (
            <div>
                <h1 id="tableLabel">Swiftlet Count</h1>
                <p>This component demonstrates fetching data from the server.</p>
                {contents}

            </div>
        );
    }

    async populateWeatherData(device) {
        const response = await fetch('api/swiftletRecord/' + device.value + '/test?customer=c1');
        const data = await response.json();
        this.setState({ forecasts: data, loading: false });

    }
}
