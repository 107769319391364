import React, { Component } from 'react';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';

export class FetchDaily extends Component {
    static displayName = FetchDaily.name;

    constructor(props) {
        super(props);
        this.state = {
            devices: [1,5,6], forecasts: [], loading: true, selected: { 'value': 1 }
        };
        this.populateWeatherData = this.populateWeatherData.bind(this);
    }

    componentDidMount() {
        this.populateWeatherData(this.state.selected);
    }

    formatDate(string) {
        var options = { year: 'numeric', month: 'long', day: 'numeric' };
        var d = new Date(string).toLocaleDateString([], options);
        return new Date(string).toLocaleDateString([], options);
    }
    renderForecastsTable(forecasts, devices, object) {
        const { populateWeatherData } = object;
        return (
            <div>
                
                <table className="table table-striped" aria-labelledby="tableLabel">
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Window ID</th>

                            <th>Count</th>

                        </tr>
                    </thead>
                    <tbody>
                        {forecasts.recorddaily.map(forecast =>
                            <tr key={(forecast.date + forecast.holeId)}>
                                <td>{this.formatDate(forecast.date)}</td>
                                <td>{forecast.holeId}</td>

                                <td>{forecast.count}</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        );
    }
    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
    handleReset = () => {
        this.state = { forecasts: [], loading: true };
        const request = fetch('SwiftletDailyRecords/reset')
            .then(function (response) {
                console.log('StartProfile2 uploaded');

            });

        setTimeout(this.fake, 2000);
        window.location.reload();
    }
    fake() {

    }
    render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : this.renderForecastsTable(this.state.forecasts, this.state.devices, this);

        return (
            <div>
                <h1 id="tableLabel">Swiftlet Daily Count</h1>
                <p>This component demonstrates fetching data from the server.</p>

                {
                  //  <button onClick={this.handleReset} style={{ margin: "2px 2px", backgroundColor: "#4DBCE9", color: "rgb(43, 43, 43)" }}>Reset
                  //  </button>
                }
                <Dropdown
                    defaultValue={1}
                    options={this.state.devices}
                    onChange={x => this.populateWeatherData(x)}
                />
                {contents}
            </div>
        );
    }

    populateWeatherData(device) {
        this.setState({ loading: true, selected: device });
        this.loadWeatherData(device.value);
        /* this.timer = setInterval(() => {
             this.loadWeatherData(device);
         }, 2000);*/
        // clearInterval(this.timer);
    }

    async loadWeatherData(device) {
        const response = await fetch('api/swiftletRecord/daily/' + device + '/test?customer=c1');
        const data = await response.json();
        this.setState({ forecasts: data, loading: false });
    }
}
